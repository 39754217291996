import Empty from "@/components/Empty";
import Loader from "@/components/Loader";
import NavBar from "@/components/NavBar";
import globalModel from "@/models/global.model";
import { hideString } from "@/utils/string";
import { useInfiniteScroll } from "ahooks";
import { Image, List, PullRefresh } from "react-vant";
import styles from "./index.module.css";

const ListView = () => {
  const { data, loading, loadMoreAsync, reloadAsync, noMore } =
    useInfiniteScroll(
      (d) => {
        return globalModel
          .invitee({
            params: {
              current: d ? d?.current + 1 : 1,
              pageSize: 20,
            },
          })
          .then((res) => res?.data);
      },
      {
        isNoMore: (d) => (d ? !d?.hasMore : true),
      }
    );
  return (
    <PullRefresh onRefresh={reloadAsync}>
      {loading ? (
        <Loader />
      ) : !data || data?.list?.length <= 0 ? (
        <Empty />
      ) : (
        <List
          className={styles.list}
          finished={noMore}
          finishedText="我是有底线的"
          onLoad={async () => {
            await loadMoreAsync();
          }}
        >
          <div className={styles.title}>
            已邀请人数 {data?.meta?.allUserCount}
          </div>
          {data?.list?.map((item) => {
            return (
              <div key={item.id} className={styles.item}>
                <div className={styles.user}>
                  <Image
                    className={styles.avatar}
                    src={
                      item.avatar ??
                      require("@/assets/images/logo-dark.png").default
                    }
                  />
                  {hideString(item.mobile, 3, 4)}
                </div>
                <div className={styles.status}>
                  {item.isReal ? <span>已实名</span> : "未实名"}
                </div>
                <div className={styles.status}>
                  {item.isWallet ? <span>已开钱包</span> : "未开钱包"}
                </div>
              </div>
            );
          })}
        </List>
      )}
    </PullRefresh>
  );
};

export default () => {
  return (
    <div className={styles.page}>
      <NavBar
        theme="none"
        title={<span style={{ color: "#fff" }}>邀请好友</span>}
      />
      <ListView />
    </div>
  );
};
